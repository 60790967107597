/** Entidad (Contactos, clientes, proveedores....)  */

import { Departament } from "./departament";
import { Employee } from "./employee";
// import { Rol } from "./Rol";
import { NgModule } from '@angular/core';


export class UserRes {
  count: Number;
  user: User[];
}
export class User {

  id: number;
  codUsuario: number;
  username: string;
  nombre: string;
  email: string;
  verFactura: boolean;
  password: string;
  fechaAlt: number;
  fechaMod: number;
  imagen: string;
  telefono: string;
  codDomicilioEntrega: string;
  rolUsuario: string;
  codigo: string;
  direccion: string;
  estado: number;
  token: string;
  idtarifav: number;
  familia: string;
  puesto: string;
  usuario: {username: string, password: string, nombre: string};
}


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "app/model/user";
import { urls } from "assets/url/urls";
import {jwtDecode} from 'jwt-decode';
import { Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class LoginService {
     headers =  {'X-TENANT-ID': 'picking'};

    /**
     * Constuctor
     */
    constructor(private http: HttpClient, private router: Router) {

    }

    // Call generate token
    // public generateToken(loginData: any) {
    //     const headers = this.headers;
    //     return this.http.post(`${urls.baseUrl}/generate-token`, loginData, {headers});
    // }

    public generateToken(loginData: any) {
        const headers = this.headers;
        return this.http.post(`${urls.baseUrl}/auth/login`, loginData, {headers});
    }

    // New session with token
    public loginUser(token: string) {
        sessionStorage.removeItem('token');
        sessionStorage.setItem('token', token);
    }

    public isLoggedIn() {
        const tokenStr = sessionStorage.getItem('token');

        if (tokenStr == null || tokenStr == undefined || tokenStr == '') {
             return false;
        } else {
            try {
                const decodedToken: any = jwtDecode(tokenStr);
            
                if (decodedToken && decodedToken.exp) {
                  const currentTimestamp = Math.floor(Date.now() / 1000);
                  return decodedToken.exp > currentTimestamp;
                } else {
                  return false; // El token no tiene una fecha de expiración válida
                }
              } catch (error) {
                console.error('Error al decodificar el token:', error);
                return false; // Error al decodificar el token
              }
        }
        return true
    }
    public startTokenValidationCheck(intervalMs: number): void {
        setInterval(() => {
          const isLoggedIn = this.isLoggedIn();
        //   console.log('isLoggedIn:', isLoggedIn);
          if (!isLoggedIn) {
            // El token ha expirado, toma alguna acción como redirigir al usuario a la página de inicio de sesión
            console.log('Token expirado');
            this.router.navigate(['/']);
          }
        }, intervalMs);
    }

    // Close session and delete tolen
    public logout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        return true;
    }

    // Get token
    public getToken() {
        return sessionStorage.getItem('token');
    }

    // Save user
    public setUser(user: User) {
        sessionStorage.removeItem('user');
        sessionStorage.setItem('user', JSON.stringify(user));
        // console.log(user);
    }

    // Get user
    public getUser() {
        const userStr =  sessionStorage.getItem('user');
        if (userStr == null || userStr == undefined || userStr == '' ) {
            return JSON.parse(userStr);
       } else {
           this.logout();
           return null;
       }
    }

    // Get User roles
    public getUserRol() {
        const user = this.getUser();
        return user.roles[0].userRoles.name;
    }

    // Get current user
    public getCurrentUser() {
        const headers = this.headers;
        headers['Authorization'] = 'Bearer ' + this.getToken();
        // console.log(headers);
        return this.http.get(`${urls.baseUrl}/user/userCurrent`, {headers});
    }

        // Get new pass
    public getNewPassword(email: String) {
            const headers = this.headers;
            return this.http.post(`${urls.baseUrl}/auth/newPassword`, {"email": email}, {headers});
        }
    

}

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
import { jwtDecode } from 'jwt-decode';
// import firebase from 'firebase/app'
import { Observable, throwError } from 'rxjs';
import { LoginService } from '../services/login/login.service';
import { User } from 'app/model/user';
import { catchError, map } from 'rxjs/operators';
import { promise } from 'protractor';
import { ok, throws } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
  private user: User;
  private admin: any;

  /**
   * Constructor
   * @param _firebaseAuth
   * @param router
   */
  constructor( public router: Router, public loginService: LoginService) {
  }

  esAdmin() {
    return this.admin = 1;
  }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

//   signinUser(username: string, password: string) {
//     this.user = new User();
//     this.user.username = username;
//     this.user.password = password;
//     console.log(this.user);
//     const  promise = new Promise<void>((resolve, reject) => {
//         this.loginService.generateToken(this.user).subscribe({
//         next: (data: any) => {
//               // console.log(data);
//               const decodedToken: any = jwtDecode(data.token);
//               if (decodedToken.roles[0] == "ROLE_ADMIN") {        
//               this.loginService.loginUser(data.token);
//               this.loginService.getCurrentUser().subscribe(
//                 (user: User) => {
//                   this.loginService.setUser(user);
//             })};
//           resolve();
//         },
//         error: (err: any) => {
//           reject(err);
//         },
//         complete: () => {
//           console.log('complete');
//         },
//       });
//     });
//   return promise;
// }

signinUser(username: string, password: string) {
  this.user = new User();
  this.user.username = username;
  this.user.password = password;
 

  return new Promise<void>((resolve, reject) => {
    this.loginService.generateToken(this.user).subscribe({
      next: (data: any) => {
        const decodedToken: any = jwtDecode(data.token);
        if (decodedToken.roles[0] === "ROLE_ADMIN") {
          this.loginService.loginUser(data.token);
          this.loginService.getCurrentUser().subscribe(
            (user: User) => {
              this.loginService.setUser(user);
              resolve();
            },
            (error: any) => {
              reject('Error fetching user data: ' + error);
            }
          );
        } else {
          reject('User does not have required role');
        }
      },
      error: (err: any) => {
        reject('Error generating token: ' + err);
      },
      complete: () => {
        console.log('complete');
      },
    });
  });
}


  logout() {
  /*  this._firebaseAuth.signOut();*/
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    this.router.navigate(['/pages/login']);
  }

  isAuthenticated() {
    return this.loginService.isLoggedIn();
  // return sessionStorage.getItem("user") != null;
  //  return true;
  }

  newPass(email: string) {
    return new Promise<void>((resolve, reject) => {
      this.loginService.getNewPassword(email).subscribe({
        next: (data: any) => {
          if(data){
            resolve();
          }else{
            reject('Email no encontrado status: ' + data.status); 
          }
        },
        error: (err: any) => {
          reject('Error generating password: ' + err);
        },
        complete: () => {
          console.log('complete');
        },
      });
    });
  }
}

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "app/model/user";
import { throwError } from "rxjs";
import { LoginService } from "../login/login.service";
import { urls } from "assets/url/urls";

@Injectable()
export class UserService {
  // Constante de la api de la base de datos
  private REST_API_SERVER = urls.baseUrl;
  headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-TENANT-ID": "picking",
  };
  requestOptions = {
    headers: new HttpHeaders(this.headers),
  };

  /**
   * Constructor
   * @param httpClient
   */
  constructor(
    private httpClient: HttpClient,
    private LoginService: LoginService,
  ) {}

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Trae los usuarios de la base de datos
   * @returns Usuario
   */
  public getUser() {
    this.LoginService.getToken() != null
      ? (this.requestOptions.headers = this.requestOptions.headers.set(
          "Authorization",
          "Bearer " + this.LoginService.getToken()
        ))
      : null;
    return this.httpClient.get(
      this.REST_API_SERVER + "/cliente",
      this.requestOptions
    );
  }

  public getUsersAdmin() {
    this.LoginService.getToken() != null
      ? (this.requestOptions.headers = this.requestOptions.headers.set(
          "Authorization",
          "Bearer " + this.LoginService.getToken()
        ))
      : null;
    return this.httpClient.get(
      this.REST_API_SERVER + "/user/users",
      this.requestOptions
    );
  }

  public getActiveUser() {
    this.LoginService.getToken() != null
      ? (this.requestOptions.headers = this.requestOptions.headers.set(
          "Authorization",
          "Bearer " + this.LoginService.getToken()
        ))
      : null;
    return this.httpClient.get(
      this.REST_API_SERVER + "/cliente/active",
      this.requestOptions
    );
  }

  /**
   * Trae el usuario con la id seleccionada
   * @param id
   * @returns Usuario por id
   */
  public getUserById(id) {
    this.LoginService.getToken() != null
    ? (this.requestOptions.headers = this.requestOptions.headers.set(
        "Authorization",
        "Bearer " + this.LoginService.getToken()
      ))
    : null;
    return this.httpClient.get(
      this.REST_API_SERVER + "/cliente/" + id,
      this.requestOptions
    );
  }

  public getUserAdminById(id) {
    this.LoginService.getToken() != null
    ? (this.requestOptions.headers = this.requestOptions.headers.set(
        "Authorization",
        "Bearer " + this.LoginService.getToken()
      ))
    : null;
    return this.httpClient.get(
      this.REST_API_SERVER + "/user/" + id,
      this.requestOptions
    );
  }

  /**
   * Crea el usuario en la base de datos
   * @param user
   * @returns Usuario
   */
  public addUser(user: User) {
    return this.httpClient.post<User>(
      this.REST_API_SERVER + "/user/register",
      user,
      this.requestOptions
    );
  }

  /**
   * Actualiza el usuario con la id seleccionada en la base de datos
   * @param id
   * @param user
   * @returns Usuario actualizado
   */
  public updateUserById(id, user: User) {
    // console.log(id);
    // console.log(user);
    this.LoginService.getToken() != null
      ? (this.requestOptions.headers = this.requestOptions.headers.set(
          "Authorization",
          "Bearer " + this.LoginService.getToken()
        ))
      : null;
    return this.httpClient.put(
      this.REST_API_SERVER + "/cliente/" + id,
      user,
      this.requestOptions
    );
  }

  public updateUserAdminById(id, name: String) {
    // console.log(id);
    // console.log(user);
    this.LoginService.getToken() != null
      ? (this.requestOptions.headers = this.requestOptions.headers.set(
          "Authorization",
          "Bearer " + this.LoginService.getToken()
        ))
      : null;
    return this.httpClient.put(
      this.REST_API_SERVER + "/user/admin/" + id,
      name,
      this.requestOptions
    );
  }

  /**
   * Crea el usuario en la base de datos
   * @param user
   * @returns Usuario
   */
  public createUser(user: User) {
    // console.log(user);
    return this.httpClient.post(
      this.REST_API_SERVER + "/user/register",
      user,
      this.requestOptions
    );
  }

  public createUserAdmin(user: User) {
    // console.log(user);
    return this.httpClient.post(
      this.REST_API_SERVER + "/user/registerAdmin",
      user,
      this.requestOptions
    );
  }

  /**
   * Borra el usuario de la base de datos
   * @param id
   * @returns Usuario
   */
  public deleteUserById(id) {
    return this.httpClient.delete(
      this.REST_API_SERVER + "/user/" + id,
      this.requestOptions
    );
  }

  /**
   * Upload Profile Picture
   */
  public uploadProfilePicture(formaData: FormData, id) {
    return this.httpClient.post(
      this.REST_API_SERVER + "/users/" + id,
      this.requestOptions
    );
  }
}

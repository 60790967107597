<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" >
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{user.nombre}}</span><span
                  class="text-right text-muted font-small-3">Disponible</span>
              </div>
              <img class="avatar" src="assets/img/gallery/logo-big.png" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <div class="dropdown-divider"></div> -->
              <!-- <a class="dropdown-item" routerLink="/pages/login"> -->
                <a class="dropdown-item mb-2" (click)="logOut()">
                 <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>

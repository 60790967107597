import { Component, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { AuthService } from "./shared/auth/auth.service";
import { LoginService } from "./shared/services/login/login.service";
import { getMessaging, getToken } from "firebase/messaging";
import { environment } from "environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  //////////////////////////////////////////
  admin: false;
  currentrole: any;
  /////////////////////////////////////////

  constructor(
    private router: Router,
    private service: AuthService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));

    this.loginService.startTokenValidationCheck(300000); // Verificar cada 5 minutos (300000 ms)

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////
  MenuDisplay() {
    this.currentrole = this.service.signinUser(this.currentrole, "ads");
  }


}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginService } from "./login.service";

@Injectable()
export class AuthIntercepror implements HttpInterceptor {


  constructor(private loginService: LoginService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
    if (req.url.startsWith('https://fcm.googleapis.com')){
        return next.handle(req); // No se intercepta, se pasa directamente al siguiente manipulador
    }
    let authReq = req;
       const token = this.loginService.getToken();
       if (token != null) {
        authReq = authReq.clone({
            setHeaders: {Authorization: `Bearer ${token}`}
        })
       }
       return next.handle(authReq);
   }
}

export const AuthInterceprorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthIntercepror,
        multi: true
    }
   ]

